import React from 'react';
import styled from 'styled-components';

import Layout from '../components/layout';
import SEO from '../components/seo';

const ViewContent = styled.div`
  background: #fff;
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  display: flex;
  flex-flow: column nowrap;
  box-shadow: 0 0 100px #1b1b2c;
`;

const ViewArticle = styled.article`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  max-width: 100%;
  text-align: center;
`;

const Maintenance = () => (
  <Layout location={typeof window !== 'undefined' ? location : null}>
    <SEO title="Maintenance" pathname="/maintenance" />
    <ViewContent>
      <ViewArticle>
        <h1>
          <strong>Maintenance</strong>
        </h1>
        <p>Revenez plus tard :)</p>
      </ViewArticle>
    </ViewContent>
  </Layout>
);

export default Maintenance;
